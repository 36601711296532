// @flow

import React from 'react'
import { observer } from 'mobx-react'
import { graphql, Link } from 'gatsby'
import { Provider } from 'mobx-react'
import debounce from 'lodash/debounce'
import includes from 'lodash/includes'
import reduce from 'lodash/reduce'
// import forEach from 'lodash/forEach'
import { observable, action } from 'mobx'

import styles from './search.module.scss'
import Metadata from '../components/Metadata'
import Markdown from '../components/Markdown'
import Divider from '../components/Divider'

type SearchProps = {
  data: any,
  location: any,
}

type Result = {
  type: string,
  title: string,
  url: string,
  externalUrl?: string,
  blurb?: string,
  tours?: any[],
  blocks?: {
    fragment: string,
    type: string,
    markdown?: string,
    title?: string,
  }[],
}

const MONTHS = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
}

const parseTitle = (title: string) => {
  return title.replace(' | Stagecoach 2019', '')
}

const parseMarkdown = (markdown: string) => {
  let markup = markdown.replace(/---/gm, ' ')
  markup = markup.replace(/\n/gm, ' ')
  markup = markup.replace(/`/gm, '')
  markup = markup.replace(/__/gm, '')
  markup = markup.replace(/#/gm, '')
  markup = markup.replace(/- /gm, ' ')
  markup = markup.replace(/"patch-large"/gm, '')
  markup = markup.replace(/"pink"/gm, '')
  markup = markup.replace(/ {4}/gm, '')
  return markup
}

@observer
class SearchPage extends React.Component<SearchProps, any> {
  @observable results: Result[] = []

  @action
  setResults = (results: Result[]): void => {
    this.results = results
  }

  data: any = {}
  stores: any

  constructor(props: SearchProps, context: any) {
    super(props, context)

    this.data = reduce(
      Object.keys(this.props.data),
      (obj: any, key: string) => {
        if (key !== 'search') {
          obj[key] = reduce(
            this.props.data[key].edges,
            (acc: any[], item: any) => {
              const data: any = item.node
              const returnData: any = {}
              if (
                includes(
                  [
                    'privacy-policy',
                    'test',
                    'terms-of-us',
                    'wristband-terms-of-use',
                  ],
                  data.url
                )
              )
                return acc
              if (includes(data.url, 'test')) return acc
              returnData.url = data.url || data.buttonUrlInternal || ''
              returnData.externalUrl = data.buttonUrlExternal || ''
              returnData.pageName = data.name
                ? data.name
                : data.metadata
                ? parseTitle(data.metadata.title)
                : ''
              if (key === 'updates') {
                if (returnData.url) returnData.url = `updates/${returnData.url}`
                if (data.blurb)
                  returnData.blurb = {
                    search: `${data.blurb.markdown.toLowerCase()} ${returnData.pageName.toLowerCase()}`,
                    markdown: data.blurb.markdown,
                  }
              } else if (key === 'pages') {
                if (data.blocks)
                  returnData.blocks = this.parseBlocks(data.blocks)
              }

              // else if (key === 'spotlight') {
              //   returnData.tourDates = []
              //   forEach(data.tours, (tour: any) => {
              //     forEach(tour.dates, (date: any) => {
              //       returnData.tourDates.push({
              //         tour: tour.title,
              //         date: this.parseDate(date.date),
              //         artist: date.artistName || tour.title,
              //         venue: date.venueName,
              //         venueUrl: date.venueUrl,
              //         location: date.venueLocation,
              //         ticketsUrl: date.ticketsUrl,
              //         search: `${tour.title.toLowerCase()} ${date.artistName.toLowerCase()} ${date.venueName.toLowerCase()} ${date.venueLocation.toLowerCase()}`,
              //       })
              //     })
              //   })
              // }
              acc.push(returnData)
              return acc
            },
            []
          )
        }
        return obj
      },
      {}
    )
  }

  search = debounce((text: string) => {
    text = text.toLowerCase()
    const results: Result[] = []
    if (text) {
      this.parseBasicPages(results, text)
      reduce(
        this.data,
        (res: Result[], type: any, key: string) => {
          // if (key === 'spotlight') {
          //   const spotlight: any = type[0]
          //   const tours: any[] = reduce(
          //     spotlight.tourDates,
          //     (tourDates: any[], tour: any) => {
          //       if (includes(tour.search, text)) {
          //         tourDates.push(tour)
          //       }
          //       return tourDates
          //     },
          //     []
          //   )
          //   if (tours.length > 0) {
          //     results.push({
          //       type: 'spotlight',
          //       title: spotlight.pageName,
          //       url: spotlight.url,
          //       tours: tours,
          //     })
          //   }
          // }
          if (key === 'pages') {
            reduce(
              type,
              (pages: any[], pageData: any) => {
                if (pageData.blocks) {
                  const blockData = reduce(
                    pageData.blocks,
                    (blocks: any[], block: any) => {
                      if (includes(block.search, text)) blocks.push(block)
                      return blocks
                    },
                    []
                  )
                  if (blockData.length > 0)
                    pages.push({
                      type: 'page',
                      title: pageData.pageName,
                      url: pageData.url,
                      blocks: blockData,
                    })
                } else if (includes(pageData.pageName, text)) {
                  pages.push({
                    type: 'page',
                    title: pageData.pageName,
                    url: pageData.url,
                  })
                }
                return pages
              },
              results
            )
          }
          if (key === 'updates') {
            reduce(
              type,
              (updatePages: any[], pageData: any) => {
                if (includes(pageData.blurb.search, text)) {
                  updatePages.push({
                    type: 'update',
                    title: pageData.pageName,
                    url: pageData.url,
                    externalUrl: pageData.externalUrl,
                    blurb: pageData.blurb.markdown,
                  })
                }
                return updatePages
              },
              results
            )
          }
          return res
        },
        results
      )
    }
    this.setResults(results)
  }, 500)

  parseDate(date: string): string {
    // 2017-08-07
    const dateArr: string[] = date.split('T')[0].split('-')
    return `${MONTHS[dateArr[1]]} ${dateArr[2]} ${dateArr[0]}`
  }

  parseBlocks(blocks: any[]) {
    return reduce(
      blocks,
      (arr: any[], block: any) => {
        const data: any = {}
        if (block.__typename === 'ContentfulPhotoBlurbBlock') {
          if (!block.blurb) return arr
          if (!block.fragmentPath) return arr
          data.fragment = block.fragmentPath
          data.search = `${block.blurb.markdown.toLowerCase()} ${block.name}`
          data.markdown = block.blurb.markdown
          data.title = block.name
          arr.push(data)
        } else if (block.__typename === 'ContentfulMapBlurbBlock') {
          if (!block.blurb) return arr
          if (!block.fragmentPath) return arr
          data.fragment = block.fragmentPath
          data.search = `${block.blurb.markdown.toLowerCase()} ${block.name}`
          data.markdown = block.blurb.markdown
          data.title = block.name
          arr.push(data)
        } else if (block.__typename === 'ContentfulTextBlock') {
          if (!block.content) return arr
          if (!block.fragmentPath) return arr
          data.fragment = block.fragmentPath
          data.search = `${block.content.markdown.toLowerCase()} ${block.name}`
          if (block.secondColumn) {
            data.search = `${
              data.search
            } ${block.secondColumn.markdown.toLowerCase()}`
          }
          if (block.thirdColumn) {
            data.search = `${
              data.search
            } ${block.thirdColumn.markdown.toLowerCase()}`
          }
          data.markdown = block.content.markdown
          if (includes(data.markdown, '![')) data.markdown = ''
          data.title = block.name
          arr.push(data)
        } else if (block.__typename === 'ContentfulQaBlock') {
          if (!block.questions) return arr
          reduce(
            block.questions,
            (qs: any[], q: any) => {
              const question: any = {}
              if (!q.fragmentPath) return qs
              question.fragment = q.fragmentPath
              question.search = `${q.question.toLowerCase()} ${q.answer.markdown.toLowerCase()}`
              question.title = q.question
              question.markdown = q.answer.markdown
              qs.push(question)
              return qs
            },
            arr
          )
        }
        return arr
      },
      []
    )
  }

  parseBasicPages(arr: Result[], text: string) {
    if (includes('newsletter email signup', text)) {
      arr.push({
        type: 'page',
        title: 'Newsletter',
        url: 'newsletter',
      })
    }
    if (includes('lineup artists set times schedule timeline', text)) {
      arr.push({
        type: 'page',
        title: 'Lineup',
        url: 'lineup',
      })
    }
    if (includes('gallery photos', text)) {
      arr.push({
        type: 'page',
        title: 'Photo Gallery',
        url: 'gallery',
      })
    }
  }

  render() {
    const LinkButton = props => {
      if (props.url) {
        return <Link to={`/${props.url}`}>{props.children}</Link>
      } else
        return (
          <a href={props.external} target={'_blank'} rel="noopener noreferrer">
            {props.children}
          </a>
        )
    }

    return (
      <Provider {...this.stores}>
        <>
          <Metadata
            data={this.props.data.search.metadata}
            location={this.props.location}
          />
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <div className={styles.search}>
                <div className={styles.input}>
                  <div className={styles.mag}>
                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 60 60">
                      <path
                        fill="black"
                        d="M59.3,55.7L40.1,36.5c3.1-3.8,4.9-8.7,4.9-14C45,10.1,34.9,0,22.5,0S0,10.1,0,22.5C0,34.9,10.1,45,22.5,45 c5.3,0,10.2-1.9,14.1-5l19.1,19.2c1,1,2.6,1,3.5,0C60.2,58.3,60.2,56.7,59.3,55.7z M22.5,40C12.9,40,5,32.1,5,22.5 C5,12.8,12.9,5,22.5,5C32.1,5,40,12.8,40,22.5C40,32.1,32.1,40,22.5,40z"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    onInput={(e: any) => this.search(e.target.value)}
                  />
                </div>
                <div className={styles.text}>SEARCH</div>
              </div>
            </div>
            <div className={styles.results}>
              <h2>Results</h2>
              {this.results.length === 0 && (
                <p className={styles.noResults}>
                  <i>{`Please enter search term above.`}</i>
                </p>
              )}
              {this.results.length > 0 &&
                this.results.map((result, index) => {
                  // if (result.type === 'spotlight') {
                  //   return (
                  //     <div
                  //       key={`${index}searchResult`}
                  //       className={styles.title}
                  //     >
                  //       <LinkButton
                  //         url={result.url || ''}
                  //         external={result.externalUrl || ''}
                  //       >
                  //         {result.title}
                  //       </LinkButton>
                  //       {result.tours &&
                  //         result.tours.map((tour, tourIndex) => {
                  //           return (
                  //             <div
                  //               key={`${tourIndex}searchResult`}
                  //               className={styles.tour}
                  //             >
                  //               {tour.date}
                  //               <br />
                  //               {tour.artist}
                  //               <br />
                  //               <a href={tour.venueUrl} target={`_blank`}>
                  //                 {tour.venue}
                  //               </a>
                  //               <br />
                  //               {tour.location}
                  //               <br />
                  //               <a href={tour.ticketsUrl} target={`_blank`}>
                  //                 Buy Tickets
                  //               </a>
                  //             </div>
                  //           )
                  //         })}
                  //       {index <= this.results.length - 2 && (
                  //         <div className={styles.divider}>
                  //           <Divider color={'#9a552a'} />
                  //         </div>
                  //       )}
                  //     </div>
                  //   )
                  // }
                  if (result.type === 'page') {
                    return (
                      <div
                        key={`${index}searchResult`}
                        className={styles.title}
                      >
                        <LinkButton
                          url={`${result.url}#content` || ''}
                          external={result.externalUrl || ''}
                        >
                          {result.title}
                        </LinkButton>
                        {result.blocks &&
                          result.blocks.map((block, blockIndex) => {
                            return (
                              <div
                                key={`${blockIndex}searchResult`}
                                className={styles.block}
                              >
                                <LinkButton
                                  url={`${result.url}#${block.fragment}`}
                                >
                                  {block.title && block.title}
                                </LinkButton>
                                {block.markdown && (
                                  <Markdown
                                    textOnly={true}
                                    markdown={parseMarkdown(block.markdown)}
                                  />
                                )}
                              </div>
                            )
                          })}
                        {index <= this.results.length - 2 && (
                          <div className={styles.divider}>
                            <Divider color={'#9a552a'} />
                          </div>
                        )}
                      </div>
                    )
                  }
                  return (
                    <div key={`${index}searchResult`} className={styles.title}>
                      <LinkButton
                        url={result.url || ''}
                        external={result.externalUrl || ''}
                      >
                        {result.title}
                      </LinkButton>
                      {result.blurb && (
                        <Markdown textOnly={true} markdown={result.blurb} />
                      )}
                      {index <= this.results.length - 2 && (
                        <div className={styles.divider}>
                          <Divider color={'#9a552a'} />
                        </div>
                      )}
                    </div>
                  )
                })}
            </div>
          </div>
        </>
      </Provider>
    )
  }
}

export const query = graphql`
  query {
    search: contentfulSearch(contentful_id: { eq: "3XEX5ueCw0eWQosesU8EcG" }) {
      metadata {
        title
        description
        image {
          file {
            url
          }
        }
        ogTitle
        ogDescription
        twitterCard
      }
      url
    }
    newsletter: allContentfulNewsletter(
      filter: { contentful_id: { eq: "1OiCIQr6rui6C8Aeu80QC6" } }
    ) {
      edges {
        node {
          url
          metadata {
            title
          }
        }
      }
    }
    gallery: allContentfulGallery(
      filter: { contentful_id: { eq: "1Vkn2fCAXOkUcgKC2Uwyk8" } }
    ) {
      edges {
        node {
          url
          metadata {
            title
          }
        }
      }
    }
    updates: allContentfulUpdateEntry(
      filter: { contentful_id: { ne: "5pd50eWNwc6Y6k448oC6uW" } }
    ) {
      edges {
        node {
          blurb {
            markdown: blurb
          }
          url
          name
          buttonUrlExternal
          buttonUrlInternal
          blocks {
            __typename
            ... on ContentfulPhotoBlurbBlock {
              name
              header {
                markdown: header
              }
              fragmentPath
              blurb {
                markdown: blurb
              }
            }
            ... on ContentfulTextBlock {
              name
              fragmentPath
              header {
                markdown: header
              }
              content {
                markdown: content
              }
              secondColumn {
                markdown: secondColumn
              }
              thirdColumn {
                markdown: thirdColumn
              }
            }
          }
        }
      }
    }
    pages: allContentfulPage(filter: { url: { ne: null } }) {
      edges {
        node {
          metadata {
            title
          }
          url
          blocks {
            __typename
            ... on ContentfulPhotoBlurbBlock {
              name
              header {
                markdown: header
              }
              fragmentPath
              blurb {
                markdown: blurb
              }
            }
            ... on ContentfulMapBlurbBlock {
              name
              blurb {
                markdown: blurb
              }
              iFrameUrl
              fragmentPath
            }
            ... on ContentfulTextBlock {
              name
              fragmentPath
              header {
                markdown: header
              }
              content {
                markdown: content
              }
              secondColumn {
                markdown: secondColumn
              }
              thirdColumn {
                markdown: thirdColumn
              }
            }
            ... on ContentfulQaBlock {
              header {
                markdown: header
              }
              questions {
                fragmentPath
                question
                answer {
                  markdown: answer
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SearchPage
